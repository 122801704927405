import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AppAppBar from './AppAppBar';
import Footer from './Footer';
import AppTheme from '../shared-theme/AppTheme';
import { Helmet } from 'react-helmet';

export default function Terms() {
  return (
    <AppTheme>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <AppAppBar />
      <Container sx={{ pt: { xs: 4, sm: 15 }, pb: { xs: 8, sm: 16 } }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: November 07, 2024
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          1. Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing this Website, accessible from https://playdo.ai, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          2. Use License
        </Typography>
        <Typography variant="body1" paragraph>
          Permission is granted to temporarily download one copy of the materials on Playdo.ai's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">modify or copy the materials;</Typography>
          </li>
          <li>
            <Typography variant="body1">use the materials for any commercial purpose or for any public display;</Typography>
          </li>
          <li>
            <Typography variant="body1">attempt to reverse engineer any software contained on Playdo.ai's Website;</Typography>
          </li>
          <li>
            <Typography variant="body1">remove any copyright or other proprietary notations from the materials; or</Typography>
          </li>
          <li>
            <Typography variant="body1">transferring the materials to another person or "mirror" the materials on any other server.</Typography>
          </li>
        </ul>
        <Typography variant="body1" paragraph>
          This will let Playdo.ai to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format. These Terms of Service has been created with the help of the Terms Of Service Generator.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          3. Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          All the materials on Playdo.ai's Website are provided "as is". Playdo.ai makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Playdo.ai does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          4. Limitations
        </Typography>
        <Typography variant="body1" paragraph>
          Playdo.ai or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Playdo.ai's Website, even if Playdo.ai or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          5. Revisions and Errata
        </Typography>
        <Typography variant="body1" paragraph>
          The materials appearing on Playdo.ai's Website may include technical, typographical, or photographic errors. Playdo.ai will not promise that any of the materials in this Website are accurate, complete, or current. Playdo.ai may change the materials contained on its Website at any time without notice. Playdo.ai does not make any commitment to update the materials.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          6. Links
        </Typography>
        <Typography variant="body1" paragraph>
          Playdo.ai has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Playdo.ai of the site. The use of any linked website is at the user's own risk.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          7. Site Terms of Use Modifications
        </Typography>
        <Typography variant="body1" paragraph>
          Playdo.ai may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          8. Your Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Please read our Privacy Policy.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          9. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          Any claim related to Playdo.ai's Website shall be governed by the laws of us without regards to its conflict of law provisions.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 4 }}>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms and Conditions, You can contact us:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">By email: team@playdo.ai</Typography>
          </li>
        </ul>
      </Container>
      <Footer />
    </AppTheme>
  );
}