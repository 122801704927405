import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './LandingPage';
import Privacy from './components/Privacy'; // Import the PrivacyPolicy component
import Terms from './components/Terms'; // Import the Terms component
import Blog from './components/Blog'; // Import the Blog component
function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/blog" element={<Blog />} />
    </Routes>
  );
}

export default App;
