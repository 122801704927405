import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import MoneyIcon from '@mui/icons-material/Money';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TerminalIcon from '@mui/icons-material/Terminal';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const items = [
  {
    icon: <VideoLabelIcon />,
    title: 'Mock Interview Experience',
    description:
      'We offer a video interview experience with real-time feedback on your performance.',
  },
  {
    icon: <MoneyIcon />,
    title: 'Striving for Perfection',
    description:
      'We make sure that you continue solving the same problem until you get it right.',
  },
  {
    icon: <QuestionAnswerIcon />,
    title: 'Personalized Hints',
    description:
      'We provide personalized hints to help you solve the problem without giving away the solution.',
  },
  {
    icon: <TerminalIcon />,
    title: 'Code Grading',
    description:
      'We grade your code and give you the test cases that you failed on (coming soon) to help you improve.',
  },
  {
    icon: <MoneyOffIcon />,
    title: 'Completely Free',
    description:
      'We are completely free for everyone to use, making us a great alternative to other platforms, which all require a subscription.',
  },
  {
    icon: <AccountCircleIcon />,
    title: 'Personalized Problems',
    description:
      'Coming soon: we\'ll give you problems that are personalized to your past performances.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Why Playdo.ai?
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why you should use Playdo.ai for your technical interview preparation over other platforms:
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}