import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppAppBar from './AppAppBar';
import Footer from './Footer';
import AppTheme from '../shared-theme/AppTheme';
import { Helmet } from 'react-helmet';

function BlogPost({ title, date, author, content }) {
  return (
    <Box sx={{ mb: 6 }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
        {date}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mb: 3 }}>
        By {author}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.8 }} paragraph>
        {content}
      </Typography>
    </Box>
  );
}

export default function Blog() {
  const posts = [
    {
      title: 'Welcome to Our Blog',
      date: 'November 8, 2024',
      author: 'Jesse Choe',
      content: 'This is the first post on our blog. Stay tuned for more updates!',
    },
    // Add more posts as needed
  ];

  return (
    <AppTheme>
      <Helmet>
        <title>Blog</title>
      </Helmet>
      <AppAppBar />
      <Container sx={{ pt: { xs: 15, sm: 15 }, pb: { xs: 8, sm: 16 } }}>
        <Typography variant="h2" component="h1" align="center" color="primary" gutterBottom sx={{ mb: 8 }}>
          Playdo.ai Blog
        </Typography>
        {posts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </Container>
      <Footer />
    </AppTheme>
  );
}
